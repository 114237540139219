import React from "react"

import Title from 'components/Title'
import Constrain from 'components/Constrain'
import Video from 'components/Video'

import Container from 'components/Container'
import Footer from "components/Footer"
import Header from "components/Header"
// import image from 'images/shop-management.png'
// import { useSiteMetadata } from "../../hooks/useSiteMetadata"

import { useTranslation } from 'gatsby-plugin-react-i18next'

const VIDEO = 'team-management'
const image = require(`images/${ VIDEO }.png`)


export default function Home() {

  const { t } = useTranslation()
  
  // const { title } = useSiteMetadata()

  return (
    <>
    <Header/>
    <main>
      <Title 
        title={ t('Team management') } 
        subtitle={ t('Manage access for your Service Advisors and Technicians from anywhere') }
        dark 
      />
      <Constrain>

        <Video poster={ image } src={`https://autoservice.nyc3.cdn.digitaloceanspaces.com/media/${ VIDEO }.mp4`} />

      </Constrain>

      <Container>

        <h1>{ t(`Manage your team from anywhere`) }</h1>

        <p>
          { t(`Grant and revoke employee access to your account whenever you need.`) }
        </p>

      </Container>
  </main>
  <Footer />
  </>
  )
}
